<template>
    <div>
      <Home_navbar HeaderClass="fixed-top"></Home_navbar>
      <!-- Breadcrumbs section starts here  -->
      <section class="breadcrumbs">
        <div class="container">   
          <div class="d-flex justify-content-between align-items-center">
             <h2 v-if="user.authenticated">Hello, {{user.data.email}} </h2>
             <h2 v-else>Login | User Dashboard </h2>
            <ol>
              <li><router-link to="/"><p class="tagline-p" v-if="user.authenticated" @click="logout()">Sign-Out!</p></router-link></li>
            </ol>
          </div>  
        </div>
      </section>      
      <section class="blog">
        <div class="container">
          <div class="row">
            <!-- The main article section starte here -->
            <div class="col-lg-8">
                <router-view></router-view>                                 
            </div>
            <!-- Article Right side bar section starts here -->
            <div class="col-lg-4">
                <UsrInsSideMenu/>
            </div>
          </div>
        </div>
      </section>      
           
      <Home_footer/>
    </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'
import {fb} from '../firebase'
import { mapGetters } from "vuex";

Vue.use(Vueaxios,axios)
  export default {
    data() {
      return {

      }
    },
    mounted()
    {
    },     
    methods: {
      // DelTableRec(SeqNo,ProfType){
      //   const InsformData = new FormData();
      //   InsformData.append('seq_no', SeqNo);
      //   if ( ProfType == 'Performer') 
      //   { InsformData.append('table_name', 'users_profile_main') }
      //   if ( ProfType == 'Troupe') 
      //   { InsformData.append('table_name', 'troupe_profile_main') }
      //   if ( ProfType == 'Academy') 
      //   { InsformData.append('table_name', 'academy_profile_main') }
      //   if (confirm('You are permenently deleting this profile, Please confirm ?'))
      //   axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=DelTableRec', InsformData)
      //   .then((resp)=>{
      //     this.RespDelRecs=resp.data.message;
      //     alert(this.RespDelRecs)
      //     this.UserProfilesByEmail()
      //     })
      //   .catch(error => {
      //     alert(error)
      //     })
      //  },

        logout() {
        fb.auth().signOut().then(() => {
          this.$router.push('/');
          console.log('Signed out!')
        })
        .catch(function(error) {
          console.log(error)});
    }      
    },

  computed: {
    // map `this.user` to `this.$store.getters.user` 
    ...mapGetters({
      user: "user"
    })
    } 
  }
</script>

<style>
.admin-main {
    box-shadow:0 0 2px rgba(0,0,0,0.63);
    border-radius:5px;
    padding: 30px 50px 20px 20px;    
}

.admin-main .admin-text h5{
  color:#e75480;
  font-weight:800;
  font-size:16px;
  margin-left:10px;
  margin-bottom:10px;
}
.blog .sidemenu {
    margin-top: 10px;
    padding: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.admin .sidemenu  .items {
    margin-bottom: 30px;
}
.admin .sidemenu .title  {
    font-size: 16px;
    font-weight: 700;
    padding: 0 0 0 0;
    margin: 0 0 15px 0;
    color: #760001;
    position: relative;
}
.admin .sidemenu .tags {
    margin-bottom: -10px;
}

.admin .sidemenu .tags ul {
    list-style: none;
    padding: 0;
}

.admin .sidemenu .tags ul li {
    display: inline-block;
}

.admin .sidemenu .tags ul a {
    color: #3f8db5;
    font-size: 14px;
    padding: 6px 14px;
    margin: 0 6px 8px 0;
    border: 1px solid #e4eff5;
    display: inline-block;
    transition: 0.3s;
}

.admin .sidemenu .tags ul a:hover {
    color: #fff;
    border: 1px solid #32627b;
    background: #e75480;
}

.admin .sidemenu .tags ul a span {
    padding-left: 5px;
    color: #bedae8;
    font-size: 14px;
}
.admin table {
  width: 100%;
}

.admin .table-container {
  overflow-x: auto;
}
</style>
